import React from "react"
import { graphql, Link, StaticQuery } from "gatsby"
import { Badge, Col, Container, Row } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus } from "@fortawesome/free-solid-svg-icons"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Functional components
const CaseStudies = ({ data }) => {
  return (
    <div className="projects-area pt-100 pb-70">
      <Container>
        <Row>
          {data.allMarkdownRemark.edges.map(({ node: study }) => (
            <Col lg={6} md={12} style={{ display: "flex" }}>
              <div className="single-projects-box">
                <Link to={"/referenz" + study.fields.slug} className="link-btn">
                  <div className="image">
                    <GatsbyImage alt={"Kundenreferenz " + study.frontmatter.customer}
                                 image={getImage(study.frontmatter.featuredimage)} />
                    <Link to={"/referenz" + study.fields.slug} className="link-btn">
                      <FontAwesomeIcon style={{ verticalAlign: 0 }} icon={faPlus} />
                    </Link>
                  </div>
                </Link>

                <div className="content">
                  <div>
                    <h2>
                      <Link to={"/referenz" + study.fields.slug} style={{ textDecoration: "none" }}>
                        {study.frontmatter.title}
                      </Link>
                    </h2>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Badge variant={"primary"} pill={true}
                             style={{
                               maxWidth: "100%",
                               textOverflow: "ellipsis",
                               whiteSpace: "nowrap",
                               overflow: "hidden",
                               display: "block"
                             }}>
                        {study.frontmatter.product.join(" | ")} | {study.frontmatter.customer}</Badge>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))
          }
        </Row>
      </Container>
    </div>
  )
}

const Main = () => (
  <StaticQuery
    query={graphql`
      query CustomerProjectsQuery {
        allMarkdownRemark(filter: {fields: {collection: {eq: "custprojects"}}, frontmatter: {disabled: {eq: false }}}, sort: { fields: [frontmatter___completed], order: DESC }) {
          edges {
            node {
              id
              frontmatter {
                customer_address
                customer
                product
                customer_website
                description
                title
                completed
                featuredimage {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
              fields {
                slug
                collection
              }
              html
            }
          }
        }
      }
    `}
    render={(data) => <CaseStudies data={data} />}
  />
)

export default Main

